.datePickerWrapper {
  position: relative;

  .button {
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    height: 16px;
  
    img {
      margin-right: 12px;
    }
  
    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.75px;
      color: #14142B;
    }
  }
  
  .picker {
    position: absolute;
    top: 30px;
    left: 0px;
    width: 300px;
    height: 336px;

    color: #1a1e35;
    background: #fff;
    box-shadow: 0 16px 32px rgba(92,107,192,0.12),0px 8px 16px rgba(59,69,123,0.12);
    border-radius: .5rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 40px;
      border-bottom: 1px solid #ebf2ff;

      padding: 5px;
      .select {
        border: none;
        background-color: transparent;
        color: #2264d1;
        // width: 90px;
      }
    }

    .main {
      display: flex;
      flex-wrap: wrap;
      padding: 4px;
      border-bottom: 1px solid #ebf2ff;

      .daysOfWeek {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-top: 4px;
        margin-bottom: 8px;

        p {
          margin: 0;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 143%;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.018em;
          color: #9696A0;
        }
      }

      .days {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        button {
          height: 40px;
          width: 40px;
          margin: 0;
          padding: 0;
          border: none;
          background: transparent;

          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.0275em;
          font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
          color: #19191D;

          &.current {
            border: 1px solid #9dc2ff;
            border-radius: 2rem;
          }

          &.active {
            background: #ebf2ff;
            border: 2px solid #2979ff;
            border-radius: 2rem;
          }

          &.anotherMonth {
            color: #76a9ff;
          }

          &:hover {
            background: #f5f8ff;
            border-radius: 2rem;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}