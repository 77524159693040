.mainPage {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #eff0f6;
}

.leftMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  padding-bottom: 24px;
  background-color: #4e4b66;

  &Profiles {
    display: flex;
    flex-direction: column;

    .logo {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 18px;
      /* or 75% */
      padding-left: 4px;

      letter-spacing: 0.75px;
      color: #F05B36;
    }

    .project {
      width: 80px;
      height: 72px;
      border: none;
      background: #4e4b66;

      img {
        width: 56px;
        height: 56px;
        background-color: #6e7191;
        border-radius: 2em;
        object-fit: scale-down;
      }

      &.isActive {
        background: linear-gradient(127.82deg, #EB0055 0%, #F4B615 98.18%);
      }

      &:hover {
        background: linear-gradient(127.82deg, #EB0055 0%, #F4B615 98.18%);
      }
    }
  }

  .logoutBtn {
    width: 48px;
    height: 40px;
    border: none;

    background: #6e7191;
    border-radius: 16px;
    padding: 8px 13px 8px 11px;
  }
}

main {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 86px 0 48px;

  .currentPostsContainer {
    width: 416px;
    margin-right: 160px;

    .emptyBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 252px;
      height: 34px;
      background: #FFFFFF;
      border-radius: 16px;

      filter: drop-shadow(0 8px 16px rgba(17, 17, 17, 0.04));

      p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        display: flex;
        align-items: center;
        letter-spacing: 0.75px;
        color: #4E4B66;
      }
    }
  }

  .newPostContainer {
    width: 564px;

    .cardWrapper {
      .card {
        width: 416px;
        height: 224px;
        filter: drop-shadow(0px 8px 16px rgba(17, 17, 17, 0.04));
  
        background: #FFFFFF;
        border-radius: 16px;
        padding: 16px;
        box-sizing: border-box;
  
        input {
          width: 98%;
        }
      }
  
      .cardPost {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 416px;
        margin-top: 14px;
        .submit {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 124px;
          height: 40px;
          background: #5F2EEA;
          border-radius: 40px;
          border: none;
  
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 28px;
          letter-spacing: 0.75px;
          color: #F7F7FC;
        }
      }
    }
  }

  .projectName {
    position: absolute;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 72px;
    line-height: 36px;
    /* identical to box height, or 50% */

    letter-spacing: 0.75px;

    color: rgba(21, 17, 209, 0.4);
    transform: rotate(-90deg);

    margin: 0;
    top: 194px;
    right: -130px;
  }
}